import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import * as CONFIG from '@/config/init.js'

Vue.use(Vuex)
const timeStamp = Math.floor(Date.now() / 1000)

export default new Vuex.Store({
  state: {    
    promoStatus:'' ,
    langText: null,
    winnersAll: [],
    apiCalls: {},
    file_url: process.env.BASE_URL+"documents/Jatekszabalyzat_ro.pdf",
    privacy_url: '',
    codeMessage: false,
    currentLang: CONFIG.defaultLanguageCode,
    shops:[]
  },
  mutations: {
    getWinners(state, response) {
      state.winnersAll = response;
    },
    setPromotionStatus(state, promoStatus) {
      state.promoStatus = promoStatus;
    },
    setFileUrl(state, text) {
      state.file_url = text;
    },
    setPrivacyUrl(state, text) {
      state.privacy_url = text;
    },
    GETTEXT(state, text) {
      state.langText = text;
    },
    GETSHOPS(state, shops) {
      state.shops = shops;
    }
  },
  actions: {   

    getWinners({ commit }) {
      return new Promise((resolve) => {
        axios.get('data/winners.json?').then(response => {
          console.log(response);
          commit("getWinners", response.data.winners)
  
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getText({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + `data/lang_${CONFIG.defaultLanguageCode}.json?` + timeStamp).then(response => {
          commit("GETTEXT", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getShops({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + `data/shops_${CONFIG.defaultLanguageCode}.json?` + timeStamp).then(response => {
          commit("GETSHOPS", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getSettings({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'status.php').then(response => {
          commit("setPromotionStatus",response.data.promo_status)
       
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    },
    getFileUrl({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'settings').then(response => {
          commit("setFileUrl",response.data.rules_file_link)
          commit("setPrivacyUrl",response.data.privacy_file_link)
       
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    }
  }
})
